import { withInput } from './form.input.hoc'
import { withSelect } from './form.select.hoc'
import { TextField } from '@material-ui/core'
import { withDatePicker } from './form.datepicker.hoc'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { fieldLabels } from './form.labels'
import { minDob, maxDob } from './form.validation'

const ForenameInput = withInput(TextField, {
    fieldName: 'Forename',
    fieldDisplayName: 'First name',
    inputProps: { maxLength: 15 }
})
const SurnameInput = withInput(TextField, {
    fieldName: 'Surname',
    fieldDisplayName: 'Last name',
    inputProps: { maxLength: 30 }
})
const EmailAddressInput = withInput(TextField, {
    fieldName: 'EmailAddress',
    fieldDisplayName: fieldLabels.EmailAddress,
    inputProps: { maxLength: 150 }
})
const TelephoneInput = withInput(TextField, {
    fieldName: 'ContactNo',
    fieldDisplayName: fieldLabels.ContactNo,
    inputProps: { maxLength: 50 }
})
const PostcodeInput = withInput(TextField, {
    fieldName: 'PostCode',
    fieldDisplayName: fieldLabels.PostCode,
    inputProps: { maxLength: 8 },
    required: true
})
const HouseNameInput = withInput(TextField, {
    fieldName: 'HouseNameFlat',
    fieldDisplayName: fieldLabels.HouseNameFlat,
    inputProps: { maxLength: 30 },
    required: false
})
const HouseNumberInput = withInput(TextField, {
    fieldName: 'HouseNumber',
    fieldDisplayName: fieldLabels.HouseNumber,
    required: false,
    inputProps: { maxLength: 10 }
})

const StreetInput = withInput(TextField, { fieldName: 'Street', inputProps: { maxLength: 40 } })
// const Address2Input = withInput(TextField, {
//     fieldName: 'Address2',
//     fieldDisplayName: fieldLabels.Address2,
//     inputProps: { maxLength: 40 },
//     required: false
// })
// const Address3Input = withInput(TextField, {
//     fieldName: 'Address3',
//     fieldDisplayName: fieldLabels.Address3,
//     inputProps: { maxLength: 40 },
//     required: false
// })
const TownInput = withInput(TextField, { fieldName: 'Town', inputProps: { maxLength: 40 } })
const CountyInput = withInput(TextField, { fieldName: 'County', inputProps: { maxLength: 40 } })

const TitleSelectList = withSelect({
    fieldName: 'Title',
    items: [
        { name: 'Mr' },
        { name: 'Mrs' },
        { name: 'Miss' },
        { name: 'Ms' },
        { name: 'Dr' },
        { name: 'Rev' },
        { name: 'Sir' }
    ]
})

const GenderSelectList = withSelect({
    fieldName: 'Gender',
    items: [{ name: 'Male', value: 'M' }, { name: 'Female', value: 'F' }]
})

const DOBInput = withDatePicker(KeyboardDatePicker, {
    fieldName: 'DOB',
    label: fieldLabels.DOB,
    maxDate: maxDob,
    maxDateMessage: 'must be more than 21 years ago',
    minDate: minDob,
    minDateMessage: 'must be more than 100 years ago'
})

export {
    ForenameInput,
    SurnameInput,
    EmailAddressInput,
    TelephoneInput,
    PostcodeInput,
    HouseNameInput,
    HouseNumberInput,
    StreetInput,
    TownInput,
    CountyInput,
    TitleSelectList,
    GenderSelectList,
    DOBInput
}
