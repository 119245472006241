import React, { Fragment } from 'react'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/react-hooks'
import ApolloClient from 'apollo-boost'

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { CssBaseline, ThemeProvider, createMuiTheme } from '@material-ui/core'
import { BrowserRouter as Router } from 'react-router-dom'
import { BaseTheme } from './theme'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import { ConfigProvider } from './state/config.state'
import { DynamicThemeProvider, useDynamicTheme } from './state/theme.state'
// import 'typeface-roboto'

// <link href="https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,600italic,700italic,800italic,400,800,700,600,300%7cOpen+Sans+Condensed:300,700,300italic" rel="stylesheet" type="text/css">

const client = new ApolloClient({
    uri: process.env.REACT_APP_GRAPHQL_URI
})

const WithThemeProvider = () => {
    const { theme } = useDynamicTheme()
    // ************** Toggle the next 2 lines to stop theme merging...
    // const mergedTheme = createMuiTheme(BaseTheme)
    const mergedTheme = createMuiTheme(BaseTheme, theme)
    return (
        <ThemeProvider theme={mergedTheme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <CssBaseline />
                <App />
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    )
}

const AppWithTheme = () => {
    return (
        <Fragment>
            <Router>
                <DynamicThemeProvider>
                    <ApolloProvider client={client}>
                        <ConfigProvider>
                            <WithThemeProvider />
                        </ConfigProvider>
                    </ApolloProvider>
                </DynamicThemeProvider>
            </Router>
        </Fragment>
    )
}

ReactDOM.render(<AppWithTheme />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
