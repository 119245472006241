import React, { FC, HtmlHTMLAttributes } from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

/*
<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
viewBox="0 0 40 40"><title>tick_1</title><path d="M14.33,34.52.7,20.9a2.39,2.39,0,0,1,3.38-3.38L14.33,27.77,35.92,6.18A2.39,2.39,0,0,1,39.3,9.55Z"/></svg>
*/
const Tick = () => (
    <SvgIcon color="primary" viewBox="0 0 40 40">
        <title>tick_1</title>
        <path d="M14.33,34.52.7,20.9a2.39,2.39,0,0,1,3.38-3.38L14.33,27.77,35.92,6.18A2.39,2.39,0,0,1,39.3,9.55Z" />
    </SvgIcon>
)

const TickCircle: FC<SvgIconProps> = (props) => (
    <SvgIcon {...props} color="primary" viewBox="0 0 100 100">
        <title>tick-large</title><g id="Layer_1" data-name="Layer 1"><path d="M71.76,37.18a3.43,3.43,0,0,0-1-2.46,3.55,3.55,0,0,0-4.92,0L44.11,56.43l-9.94-9.94a3.57,3.57,0,0,0-4.92,0,3.49,3.49,0,0,0,0,4.92L44.11,66.27,70.74,39.64A3.45,3.45,0,0,0,71.76,37.18Z"/><path d="M77.37,9.35a2.44,2.44,0,1,0-2.74,4.05A44.11,44.11,0,0,1,49.94,94.1a2.45,2.45,0,1,0,0,4.9A49,49,0,0,0,77.37,9.35Z"/><circle cx="59.04" cy="3.45" r="2.45"/><path d="M67.86,8.83a2.45,2.45,0,1,0-2.45-2.45A2.45,2.45,0,0,0,67.86,8.83Z"/><path d="M25.37,86.6A44.11,44.11,0,0,1,50.06,5.9a2.45,2.45,0,0,0,0-4.9A49,49,0,0,0,22.63,90.65a2.4,2.4,0,0,0,1.37.42,2.44,2.44,0,0,0,1.37-4.47Z"/><path d="M41,94.1a2.45,2.45,0,1,0,2.45,2.45A2.45,2.45,0,0,0,41,94.1Z"/><circle cx="32.15" cy="93.62" r="2.45"/></g>
    </SvgIcon>
)

export { Tick, TickCircle }
