import React, { ComponentType, useState, useEffect } from 'react'
import { OutlinedInputProps } from '@material-ui/core'
import omit from 'lodash.omit'
import debounce from 'lodash.debounce'

import { StateProps } from '../../state/form.state'
import { EligibilityFormFields, FieldValidator } from './form.types'

interface DefaultInputProps extends Partial<OutlinedInputProps> {
    fieldName: keyof EligibilityFormFields
    fieldDisplayName?: string
    validators?: FieldValidator[]
    readOnly?: boolean
}

const withInput = <P extends {}>(Component: ComponentType<P>, defaultProps: DefaultInputProps) => {
    const {
        fieldName,
        fieldDisplayName = fieldName,
        required = true,
        readOnly = false,
        type = 'text',
        inputProps = {}
    } = defaultProps
    const WithInput = (props: StateProps & Partial<P>) => {
        const { state, dispatch } = props
        const fieldStateValue = state[fieldName] || ''
        const [fieldState, setFieldState] = useState<string>(fieldStateValue)
        useEffect(() => {
            if (props.state.lastChange !== fieldName) {
                setFieldState(fieldStateValue)
            }
        }, [fieldStateValue, props.state])
        const mergedInputProps = { ...inputProps, readOnly }

        const currentErrors = state.errors[fieldName]
        const helperText = currentErrors && !currentErrors.isValid ? currentErrors.errors[0].message : ''
        const delayDispatch = debounce((value: string) => {
            dispatch({
                type: 'update',
                state: { [fieldName]: value }
            })
        }, 200)

        return (
            <Component
                id={fieldName}
                value={fieldState}
                label={fieldDisplayName}
                onChange={(event: React.FormEvent<HTMLInputElement>) => {
                    const value = event.currentTarget.value
                    setFieldState(value)
                    delayDispatch(value)
                }}
                inputProps={mergedInputProps}
                required={required}
                helperText={helperText}
                error={helperText !== ''}
                type={type}
                variant="outlined"
                fullWidth
                {...(({ ...omit(props, 'state', 'dispatch') } as unknown) as Required<P>)}
            />
        )
    }
    return WithInput
}

export { withInput }
