import gql from 'graphql-tag'

const AddressSearchQuery = gql`
    query AddressSearch($postcode: String!) {
        addressSearch(postcode: $postcode) {
            results {
                id
                address
            }
        }
    }
`

const AddressLookupQuery = gql`
    query AddressLookup($lookupId: String!) {
        addressLookup(id: $lookupId) {
            HouseNumber
            Flat
            HouseName
            Street
            Town
            County
            Postcode
        }
    }
`

export { AddressSearchQuery, AddressLookupQuery }
