import React, { useState } from 'react'
import {
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    SelectProps,
    makeStyles,
    createStyles,
    FormHelperText
} from '@material-ui/core'
import { StateProps } from '../../state/form.state'
import omit from 'lodash.omit'
import { EligibilityFormFields } from './form.types'

interface SelectDefaultProps extends Partial<SelectProps> {
    fieldName: keyof EligibilityFormFields
    fieldDisplayName?: string
    items: SelectListItem[]
    onSelected?: (value: string) => void
}

interface SelectListItem {
    name: string | number
    value?: string | number
}

const useStyles = makeStyles(theme =>
    createStyles({
        formControl: {
            minWidth: 180
        },
        selectEmpty: {
            marginTop: theme.spacing(2)
        }
    })
)

const withSelect = (defaultProps: SelectDefaultProps) => {
    const { fieldName, fieldDisplayName = fieldName, placeholder, items, required = false, ...rest } = defaultProps
    const WithSelect = (props: Partial<SelectDefaultProps> & StateProps) => {
        const { state, dispatch, onSelected } = props
        const classes = useStyles()
        const inputLabel = React.useRef<HTMLLabelElement>(null)
        const [labelWidth, setLabelWidth] = React.useState(0)
        const [open, setOpen] = useState(false)
        if (props.open && !open) setOpen(true)

        React.useEffect(() => {
            setLabelWidth(inputLabel.current!.offsetWidth)
        }, [])
        const currentErrors = state.errors[fieldName]
        const helperText = currentErrors && !currentErrors.isValid ? currentErrors.errors[0].message : ''

        return (
            <FormControl
                variant="outlined"
                fullWidth
                required={required}
                className={classes.formControl}
                error={helperText !== ''}
                disabled={rest.disabled}
            >
                <InputLabel ref={inputLabel} id={`${fieldName}-label`}>
                    {fieldDisplayName}
                </InputLabel>
                <Select
                    labelId={`${fieldName}-label`}
                    value={state[fieldName] || ''}
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                        dispatch({
                            type: 'update',
                            state: { [fieldName]: event.target.value }
                        })
                        if (event.target.value && onSelected) {
                            onSelected(event.target.value as string)
                        }
                    }}
                    labelWidth={labelWidth}
                    {...omit(defaultProps, 'fieldName', 'fieldDisplayName', 'onSelected')}
                >
                    {placeholder && (
                        <MenuItem value="" disabled>
                            {placeholder}
                        </MenuItem>
                    )}
                    {(props.items || items).map(item => (
                        <MenuItem key={item.name} value={item.value || item.name}>
                            {item.name}
                        </MenuItem>
                    ))}
                </Select>
                {helperText && <FormHelperText>{helperText}</FormHelperText>}
            </FormControl>
        )
    }

    return WithSelect
}

export { withSelect }
