import React, { FC, useState } from 'react'
import { Grid, Container, Button, Hidden, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { LoanPanel } from './LoanPanel'
import { QuotePanel } from './QuotePanel'
import { EligibilityStepper } from '../../components/EligibilityStepper'
import { Hero } from '../../components/Hero'
import { findBestProduct } from '../../service/product.finder'
import { useHistory, useLocation } from 'react-router'
import { FinanceCalculator } from '../../service/calculator'
import { useStylesBase } from '../../styles/shared-styles'
import { useLoanState } from '../../state/loan.state'
import { useConfig } from '../../state/config.state'
import queryString from 'query-string'

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        backgroundColor: theme.palette.primary.main
        // color: theme.palette.text.primary
    },
    test: {
        paddingTop: theme.spacing(1),
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5
    },
    h5: {
        color: theme.palette.primary.contrastText
    },
    loanContainer: {
        borderRight: `1px solid ${theme.palette.primary.main}`
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.common.white,
        'backdrop-filter': 'blur(2px)'
    },

    holdingTextContainer: {
        borderRadius: 10,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        padding: theme.spacing(2)
    }
}))

// let quote: Quote
// TODO the state for this is a mess and should be a lot simpler
const Calculator: FC<{}> = () => {
    const classes = useStyles()
    const classesBase = useStylesBase()
    const history = useHistory()
    const { search } = useLocation()

    const {
        config: { configuration }
    } = useConfig()
    const { loan, updateLoan } = useLoanState()
    const { product, detail } = loan

    const parsedParams = queryString.parse(search)

    if ((!parsedParams.retailerId || !parsedParams.apikey) && window) {
        window.location.href = 'https://www.chrysalisfinance.co.uk'
        return <>Redirecting ...</>
    }

    const CheckButton = (
        <Grid item>
            <Button
                variant="contained"
                color="secondary"
                disabled={!(product && configuration)}
                onClick={() => {
                    history.push('/eligibility')
                }}
            >
                Check your eligibility
            </Button>
        </Grid>
    )

    const showQuote = detail && configuration && product

    return (
        <>
            <Hero>
                <Typography variant="h4" component="h1" color="primary">
                    Find out now if you would be eligible for a Chrysalis Payment Plan
                </Typography>
            </Hero>
            <Container fixed maxWidth="md" className={classesBase.spacingVertical}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={8}>
                        <LoanPanel />
                        <Hidden smDown>
                            <Grid container justify="flex-end">
                                {CheckButton}
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        {showQuote && <QuotePanel product={product} configuration={configuration} />}
                        {!showQuote && (
                            <Container className={classes.holdingTextContainer}>
                                <Typography variant="h6">
                                    Enter a valid estimated treatment cost to see payment plan calculations.
                                </Typography>
                            </Container>
                        )}
                    </Grid>
                    <Hidden mdUp>
                        <Grid container justify="center">
                            {CheckButton}
                        </Grid>
                    </Hidden>
                </Grid>
            </Container>
            <section>
                <EligibilityStepper />
            </section>
        </>
    )

    // return (
    //     <>
    //         <Grid container>
    //             <Grid container style={{ paddingLeft: 30 }}>
    //                 <Grid item xs={8} style={{ paddingRight: 35 }} className={classes.loanContainer}></Grid>
    //                 <Grid item xs={4} style={{ paddingLeft: 5 }}>
    //                     {config && <QuotePanel loan={loan} configuration={config} />}
    //                 </Grid>
    //             </Grid>
    //         </Grid>
    //     </>
    // )
}

export { Calculator }
