import React, { FC, Fragment } from 'react'
import ReactMinimalPieChart from 'react-minimal-pie-chart'
import { useTheme, Theme, Color } from '@material-ui/core'
import { primaryColor } from '../../theme/utils'
import { formattedNumber, formattedAmount } from '../../utils/number.utils'

interface Props {
    monthlyPayment?: string
    loanAmount: number
    depositAmount: number
    interestPayable: number
}

const buildChartData = (theme: Theme, interestPayable: number, loanAmount: number, depositAmount: number) => {
    const primary = primaryColor(theme)
    return [
        {
            color: theme.palette.text.primary,
            title: 'Interest',
            value: interestPayable
        },
        {
            color: primary[500],
            title: 'Loan',
            value: loanAmount
        },
        {
            color: primary[100],
            title: 'Deposit',
            value: depositAmount
        }
    ]
}

let toggle = false
const DonutChart: FC<Props> = (props: Props) => {
    const { monthlyPayment = '0', interestPayable, loanAmount, depositAmount } = props
    const theme = useTheme()
    const data = buildChartData(theme, interestPayable, loanAmount, depositAmount)

    const Donut = () => (
        <ReactMinimalPieChart
            animate={true}
            animationDuration={500}
            animationEasing="ease-out"
            cx={50}
            cy={50}
            data={data}
            // label={p => {
            //     return p.dataIndex === 0 ? `£${monthlyPayment.split('.')[0]}` : ''
            // }}
            // text-anchor="middle" dominant-baseline="middle" fill="#303f9f" x="50" y="50" dx="0" dy="0" style="font-size: 0.875rem; font-weight: 500; font-family: Roboto, Helvetica, Arial, sans-serif;"
            label={p =>
                p.dataIndex === 0 ? (
                    <Fragment key={p.dataIndex + '_data'}>
                        <text
                            fontSize={theme.typography.pxToRem(5)}
                            fill={theme.palette.grey[600]}
                            x="25%"
                            y="40%"
                            dx="0"
                            dy="0"
                        >
                            MONTHLY PAYMENT
                        </text>
                        <text
                            fontSize={theme.typography.button.fontSize}
                            fontWeight={theme.typography.button.fontWeight}
                            fill={theme.palette.text.primary}
                            x="32"
                            y="58"
                            dx="0"
                            dy="0"
                        >
                            £{(formattedAmount(monthlyPayment) || '.').split('.')[0]}
                        </text>
                        <text
                            fontSize={theme.typography.fontSize / 2}
                            fontWeight={theme.typography.fontWeightLight}
                            fill={theme.palette.text.primary}
                            x="45"
                            y="70"
                            dx="0"
                            dy="0"
                        >
                            .{(monthlyPayment || '.').split('.')[1]}
                        </text>
                    </Fragment>
                ) : (
                    ''
                )
            }
            // labelStyle={{ fontSize, fontWeight, fontFamily, fontStyle }}
            labelPosition={0}
            lengthAngle={360}
            lineWidth={8}
            onClick={undefined}
            onMouseOut={undefined}
            onMouseOver={undefined}
            paddingAngle={0}
            radius={40}
            rounded={false}
            startAngle={0}
            viewBoxSize={[100, 100]}
        />
    )
    toggle = !toggle
    return toggle ? <Donut /> : <Donut />
}

export { DonutChart }
