import gql from 'graphql-tag'

const EligibilityMutation = gql`
    mutation EligibilityService($input: EligibilityInput!) {
        eligibilityCheck(input: $input) {
            RequestId
            PatientId
            RequestOutcome
            RequestOutcomeCode
            RequestOutcomeMessage
            AddressMatch
            MultiAddressResults
            PreScoreDecision
            PreScoreRAG
            MinMaxLoanEnabled
            MinLoan
            MaxLoan
            ResponseDate
            Errors
        }
    }
`

export { EligibilityMutation }
