import { Slider, withStyles } from '@material-ui/core'
import { primaryColor } from '../theme/utils'

const DepositAmountSlider = withStyles(
    theme => ({
        valueLabel: {
            width: 75,
            height: 34,
            backgroundColor: primaryColor(theme)[100],
            color: theme.palette.primary.main,
            transform: 'none',
            fontSize: 16,
            minWidth: 30,

            '& > span': {
                backgroundColor: 'transparent',

                '& > span': {
                    color: theme.palette.text.primary
                }
            }
        },

        thumb: {
            backgroundColor: primaryColor(theme)[100],
            position: 'relative',

            '&:before': {
                top: -12,
                content: '""',
                position: 'absolute',
                width: 0,
                height: 0,
                borderWidth: 10,
                borderStyle: 'solid',
                borderTopColor: primaryColor(theme)[100],
                borderBottomColor: 'transparent',
                borderRightColor: 'transparent',
                borderLeftColor: 'transparent'
            }
        },

        track: {
            color: primaryColor(theme)[100],
            height: 3
        },
        rail: {
            height: 3
        },
        mark: {
            height: 3
        }
    }),
    { withTheme: true }
)(Slider)

export { DepositAmountSlider }
