import { FinanceCalculator } from './calculator'
import groupBy from 'lodash.groupby'
import sortBy from 'lodash.sortby'
import { GetConfiguration_eligibilityConfiguration_EligibilityProducts_RetailerCreditGroupValueBands as FinanceProduct } from '../forms/calculator/__generated__/GetConfiguration'
// import sortBy from 'lodash.sortby'

const findBestProduct = (
    products: FinanceProduct[],
    loanAmount: number,
    term?: number
): FinanceCalculator | undefined => {
    const matchingResults = products
        .filter(product => product.ValueFrom <= loanAmount && product.ValueTo >= loanAmount)
        .filter(product => product.Term === term)
        .map(product => {
            const result = new FinanceCalculator(product)
            result.calculatePrice(loanAmount)
            return result
        })

    const aprResults = groupBy(matchingResults, p => (p.interestFree ? 'IF' : 'IB'))
    const bestInterestFree = sortBy(aprResults['IF'], 'monthlyPaymentValue')[0]
    const bestInterestBearing = sortBy(aprResults['IB'], 'monthlyPaymentValue')[0]
    // var bestInterestBearing = sortBy(aprResults[false], 'monthlyPaymentValue')[0]
    // return {
    //     all: matchingResults,
    //     first: bestInterestFree || bestInterestBearing,
    //     second: bestInterestFree ? bestInterestBearing : undefined,
    //     hasResult: bestInterestFree != undefined || bestInterestBearing != undefined,
    //     example: bestInterestBearing || bestInterestFree
    // }
    return bestInterestFree || bestInterestBearing
}

export { findBestProduct }

// service('findBestFinanceProducts', function(FinanceCalculator) {
//     return function findBestFinanceProducts(financeProducts, totalCredit) {
//         var matchingResults = _.map(financeProducts, function(product) {
//             if (product.MinLoan <= totalCredit && product.MaxLoan >= totalCredit) {
//                 var result = new FinanceCalculator(product)
//                 result.calculatePrice(totalCredit)
//                 return result
//             }
//         })
//         var aprResults = _.groupBy(matchingResults, 'interestFree')
//         var bestInterestFree = _.sortBy(aprResults[true], 'monthlyPaymentValue')[0]
//         var bestInterestBearing = _.sortBy(aprResults[false], 'monthlyPaymentValue')[0]
//         return {
//             all: matchingResults,
//             first: bestInterestFree || bestInterestBearing,
//             second: bestInterestFree ? bestInterestBearing : undefined,
//             hasResult: bestInterestFree != undefined || bestInterestBearing != undefined,
//             example: bestInterestBearing || bestInterestFree
//         }
//     }
// })
