import React, { PropsWithChildren } from 'react'
import { makeStyles, createStyles, Container } from '@material-ui/core'
import { useStylesBase } from '../styles/shared-styles'
import { primaryColor } from '../theme/utils'

const useStyles = makeStyles(theme =>
    createStyles({
        hero: {
            backgroundColor: primaryColor(theme)[50],
            //backgroundColor: '#E9EFF4',
            textAlign: 'center'
        }
    })
)

type HeroProps = PropsWithChildren<{}>

const Hero = ({ children }: HeroProps) => {
    const classesBase = useStylesBase()
    const { hero } = useStyles()
    return (
        <section className={hero}>
            <Container fixed maxWidth="md" className={classesBase.spacingVertical}>
                {children}
            </Container>
        </section>
    )
}

export { Hero }
