import React, { FC } from 'react'
import { Grid, Typography, Container, Hidden } from '@material-ui/core'
import { useHistory } from 'react-router'
import { useGoogleReCaptcha, IGoogleReCaptchaConsumerProps } from 'react-google-recaptcha-v3'

import { useEligibilityForm } from '../../state/form.state'
import {
    ForenameInput,
    SurnameInput,
    EmailAddressInput,
    TitleSelectList,
    GenderSelectList,
    TelephoneInput,
    PostcodeInput,
    HouseNameInput,
    HouseNumberInput,
    StreetInput,
    TownInput,
    CountyInput,
    DOBInput
} from './form.fields'
import { validateForm, ValidationErrors } from './form.submit'
import { useMutation } from '@apollo/react-hooks'
import {
    EligibilityService,
    EligibilityServiceVariables,
    EligibilityService_eligibilityCheck
} from './__generated__/EligibilityService'
import { EligibilityInput } from '../../__generated__/globalTypes'
import { EligibilityMutation as mutation } from './mutation'
import { AddressSearchList } from './address-search/address-search'
import { SubmitButton } from './submit.button'
import { captchaTokenAsContext } from '../../utils/graphql-context.utils'
import { PaymentPlan } from '../../components/PaymentPlan'
import { EligibilityStepper } from '../../components/EligibilityStepper'
import { Hero } from '../../components/Hero'
import { useStylesBase } from '../../styles/shared-styles'
import { useLoanState } from '../../state/loan.state'
import { useConfig } from '../../state/config.state'
import { Link } from '@material-ui/core'

const EligibilityChecker: FC<{}> = () => {
    const classes = useStylesBase()
    const history = useHistory()
    const { loan } = useLoanState()
    const { config } = useConfig()
    const { executeRecaptcha } = useGoogleReCaptcha() as Required<IGoogleReCaptchaConsumerProps>
    const { state, dispatch } = useEligibilityForm()

    const [eligibilityCheck] = useMutation<EligibilityService, EligibilityServiceVariables>(mutation)
    const inputProps = { state, dispatch }

    // let errorCode = ''
    // if (error) {
    //     errorCode =
    //         error.graphQLErrors && error.graphQLErrors.length && error.graphQLErrors[0].extensions
    //             ? error.graphQLErrors[0].extensions.code
    //             : ''
    // }
    return (
        <form noValidate>
            <Hero>
                <Typography variant="h4" component="h1" color="primary">
                    Check your eligibility for a payment plan, without affecting your credit rating
                </Typography>
            </Hero>
            <Container fixed maxWidth="md" className={classes.spacingVertical}>
                <Grid container spacing={2}>
                    <Grid item sm={12} md={8}>
                        <Typography variant="h5" component="h2" color="primary">
                            Your details
                        </Typography>
                        <Typography className={classes.spacingVerticalTopSm}>
                            Please complete the form below and then click submit for an instant response without
                            affecting your credit rating.
                        </Typography>{' '}
                        <Grid container spacing={1}>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography
                                    variant="h6"
                                    component="h3"
                                    color="primary"
                                    className={classes.spacingVerticalTopMd}
                                >
                                    Personal Details
                                </Typography>
                            </Grid>

                            <Grid item sm={4} md={4}>
                                <TitleSelectList {...inputProps} />
                            </Grid>
                            <Grid item sm={8} md={8}></Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <ForenameInput {...inputProps} />
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <SurnameInput {...inputProps} />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <GenderSelectList {...inputProps} />
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <DOBInput {...inputProps} />
                            </Grid>
                            <Hidden only="xs">
                                <Grid item sm={4} md={4}></Grid>
                            </Hidden>
                            <Grid item xs={12} sm={12} md={12}>
                                <EmailAddressInput {...inputProps} />
                            </Grid>
                            <Grid item sm={6} md={6}>
                                <TelephoneInput {...inputProps} />
                            </Grid>
                            <Hidden only="xs">
                                <Grid item sm={6} md={6}></Grid>
                            </Hidden>
                            <Grid item xs={12} sm={12} md={12}>
                                <Typography
                                    variant="h6"
                                    component="h3"
                                    color="primary"
                                    className={classes.spacingVerticalTopMd}
                                >
                                    Current address
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={4} md={4}>
                                <PostcodeInput {...inputProps} />
                            </Grid>
                            <Grid item xs={12} sm={8} md={8}>
                                <AddressSearchList {...inputProps} />
                            </Grid>
                            {state.AddressLookupId && (
                                <>
                                    <Grid item xs={6} sm={6} md={6}>
                                        <HouseNameInput {...inputProps} />
                                    </Grid>
                                    <Grid item xs={6} sm={3} md={3}>
                                        <HouseNumberInput {...inputProps} />
                                    </Grid>
                                    <Grid item xs={6} sm={3} md={3}>
                                        <StreetInput {...inputProps} />
                                    </Grid>
                                    <Hidden xsUp>
                                        <Grid item xs={6}></Grid>
                                    </Hidden>
                                    <Hidden smUp>
                                        <Grid item md={4}></Grid>
                                    </Hidden>
                                    <Grid item xs={12} md={6}>
                                        <TownInput {...inputProps} />
                                    </Grid>
                                    <Grid item xs={12} md={4}></Grid>
                                    <Grid item xs={12} md={6}>
                                        <CountyInput {...inputProps} />
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        <Typography
                            variant="h6"
                            component="h3"
                            color="primary"
                            className={classes.spacingVerticalTopMd}
                        >
                            I understand that:
                        </Typography>
                        <Typography variant="body2" className={classes.spacingVerticalTopSm}>
                            By submitting my eligibility details, I am consenting to Chrysalis Finance and its partners
                            performing a soft search to calculate my borrowing power, and this will not harm my credit
                            rating. I am also consenting to Chrysalis Finance passing my details back to the treatment
                            provider and using my personal data in accordance with its{' '}
                            <Link
                                color="secondary"
                                href="https://chrysalisfinance.co.uk/privacy-notice/"
                                target="_blank"
                                rel="noopener"
                            >
                                Privacy Notice
                            </Link>
                            .
                        </Typography>
                        <Typography variant="body2" className={classes.spacingVerticalTopSm}>
                            Chrysalis Finance provides a personalised service. Our technology uses your data to show
                            your eligibility for a medical loan that matches your credit profile.
                            <p>
                                Eligibility does not constitute an offer as this will be dependent on the treatment you
                                have booked and your personal circumstances at the time of applying for your loan.
                            </p>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        {loan && <PaymentPlan {...loan} />}
                    </Grid>
                    <Grid item xs={12} sm={7} md={4}>
                        <Typography variant="body1" color="primary">
                            Click Submit to see your instant response without affecting your credit rating
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={5} md={4}>
                        <Grid container justify="center">
                            <SubmitButton
                                state={state}
                                onSubmitForm={setLoading =>
                                    validateForm(config, loan, state)
                                        .then(input => {
                                            return executeRecaptcha('eligibility_check_submit').then(token => [
                                                token,
                                                input
                                            ]) as Promise<[string, EligibilityInput]>
                                        })
                                        .then(([token, input]) =>
                                            eligibilityCheck({
                                                ...captchaTokenAsContext(token),
                                                variables: { input }
                                            }).then(r => {
                                                const result = (r.data &&
                                                    r.data.eligibilityCheck) as EligibilityService_eligibilityCheck
                                                let resultPath =
                                                    result.PreScoreRAG === 'R' || result.PreScoreRAG === 'X' ? '/eligibility/thank-you' : ''
                                                if (!resultPath) {
                                                    resultPath =
                                                        result.PreScoreRAG === 'G'
                                                            ? '/eligibility/eligible'
                                                            : '/eligibility/eligible-to-apply'
                                                }

                                                history.push(resultPath)
                                            })
                                        )
                                        .catch(e => {
                                            if (e instanceof ValidationErrors) {
                                                dispatch({ type: 'errors', state: { errors: e.errors } })
                                            } else {
                                                console.error('error during submit', e)
                                            }
                                        })
                                        .finally(() => {
                                            setLoading(false)
                                        })
                                }
                            />
                        </Grid>
                    </Grid>
                    <Hidden smDown>
                        <Grid item md={4}></Grid>
                    </Hidden>
                </Grid>
            </Container>
            <section>
                <EligibilityStepper position={1} />
            </section>
        </form>
    )
}

export { EligibilityChecker }
