import React from 'react'
import { Grid, Typography, Container, Link, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core'
import { EligibilityStepper } from '../components/EligibilityStepper'
import { Hero } from '../components/Hero'
import { useStylesBase } from '../styles/shared-styles'
import { useEligibilityForm } from '../state/form.state'
import { useLoanState } from '../state/loan.state'
import { PaymentPlan } from '../components/PaymentPlanTop'
import { Tick, TickCircle } from '../components/Tick'
import { AnyQuestions } from '../components/AnyQuestions'

const EligibleToApply = () => {
    const classes = useStylesBase()

    const { state } = useEligibilityForm()
    const { loan } = useLoanState()

    const { Forename, Surname } = state
    const name = `${Forename} ${Surname}`

    return (
        <>
            <Hero>
                <TickCircle className={classes.largeTick} />
                <Typography variant="h3" component="h1" color="primary">
                    Great news {name}, you have a fair chance of being accepted
                </Typography>
            </Hero>
            <Container fixed maxWidth="sm" className={classes.spacingVerticalTopMd}>
                <Grid item xs={12}>
                    {loan && <PaymentPlan {...loan} />}
                </Grid>
            </Container>
            <Container fixed maxWidth="sm" className={classes.spacingVertical}>
                <Grid item xs={12}>
                    <Typography variant="h4" component="h2" color="primary" align="center">
                        What happens next?
                    </Typography>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <Tick />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1" className={classes.spacingVerticalTopSm}>
                                    You have a fair chance of being accepted
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Tick />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1" className={classes.spacingVerticalTopSm}>
                                    One of the team at Chrysalis Finance will be in touch shortly to talk over the
                                    options available
                                </Typography>
                            </ListItemText>
                        </ListItem>
                        <ListItem>
                            <ListItemIcon>
                                <Tick />
                            </ListItemIcon>
                            <ListItemText>
                                <Typography variant="body1" className={classes.spacingVerticalTopSm}>
                                    You will also receive an email with more details
                                </Typography>
                            </ListItemText>
                        </ListItem>
                    </List>
                </Grid>
            </Container>
            <Container fixed maxWidth="sm" className={classes.spacingVerticalTopMd}>
                <AnyQuestions />
            </Container>
            <Grid item xs={12} className={classes.spacingVerticalTopMd}>
                <EligibilityStepper position={2} />
            </Grid>
            <section className={classes.footerEligibilty}>
                <Container fixed maxWidth="md">
                    <Typography variant="caption">
                        In order to check your eligibility, we have supplied your personal information to credit
                        reference agencies (CRAs). By working with the CRAs we do a pre-application check of your credit
                        ﬁle using the Eligibility Checker. You’ll be able to see the search on your ﬁle, but it won’t
                        show up to other organisations. This means there is no impact to your credit rating.
                    </Typography>
                </Container>
            </section>
        </>
    )
}

export { EligibleToApply }
