import React from 'react'
import { Grid, Typography, Container, ListItemText, List, ListItem, ListItemIcon } from '@material-ui/core'
import { EligibilityStepper } from '../components/EligibilityStepper'
import { Hero } from '../components/Hero'
import { useStylesBase } from '../styles/shared-styles'
import { useEligibilityForm } from '../state/form.state'
import { Tick } from '../components/Tick'
import { AnyQuestions } from '../components/AnyQuestions'

const NotEligible = () => {
    const classes = useStylesBase()

    const { state } = useEligibilityForm()

    const { Forename, Surname } = state
    const name = `${Forename} ${Surname}`

    return (
        <>
            <Hero>
                <Typography variant="h3" component="h1" color="primary">
                    Thank you {name}
                </Typography>
            </Hero>
            <Container fixed maxWidth="sm" className={classes.spacingVertical}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h4" component="h2" color="primary" align="center">
                            What happens next?
                        </Typography>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <Tick />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body1" className={classes.spacingVerticalTopSm}>
                                        Your eligibility check has been sent to the Chrysalis team for review.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <Tick />
                                </ListItemIcon>
                                <ListItemText>
                                    <Typography variant="body1" className={classes.spacingVerticalTopSm}>
                                        They will be in touch with you shortly.
                                    </Typography>
                                </ListItemText>
                            </ListItem>
                        </List>
                        <AnyQuestions />
                    </Grid>
                </Grid>
            </Container>
            <Grid item xs={12}>
                <EligibilityStepper position={2} />
            </Grid>
            <section className={classes.footerEligibilty}>
                <Container fixed maxWidth="md">
                    <Typography variant="caption">
                        In order to check your eligibility, we have supplied your personal information to credit
                        reference agencies (CRAs). By working with the CRAs we do a pre-application check of your credit
                        ﬁle using the Eligibility Checker. You’ll be able to see the search on your ﬁle, but it won’t
                        show up to other organisations. This means there is no impact to your credit rating.
                    </Typography>
                </Container>
            </section>
        </>
    )
}

export { NotEligible }
