import { FieldLabels } from './form.types'

export const fieldLabels: FieldLabels = {
    Title: 'Title',
    Forename: 'First name',
    Surname: 'Last name',
    Gender: 'Gender',
    EmailAddress: 'Email address',
    ContactNo: 'Telephone number',
    PostCode: 'Postcode',
    HouseNameFlat: 'House/Flat name',
    HouseNumber: 'House Number',
    Street: 'Street',
    Town: 'Town',
    County: 'County',
    // Address2: 'Address Line 2',
    // Address3: 'Address Line 3',
    DOB: 'Date of birth'
}
