import React from 'react'

import { Link, Typography } from '@material-ui/core'
import { useStylesBase } from '../styles/shared-styles'

const AnyQuestions = () => {
    const classes = useStylesBase()
    return (
        <Typography variant="body1" className={classes.spacingVerticalTopXs}>
            If you have any questions, you can phone the Chrysalis Contact Centre on <Link color="secondary" href="tel:0333 32 32 230">0333 32 32 230</Link> or visit&nbsp;
            <Link color="secondary" href="www.chrysalisﬁnance.com">
                www.chrysalisﬁnance.co.uk
            </Link>
            .
        </Typography>
    )
}

export { AnyQuestions }
