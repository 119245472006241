import React, { useMemo } from 'react'

import { useLazyQuery, useApolloClient, QueryLazyOptions } from '@apollo/react-hooks'
import { AddressSearchQuery, AddressLookupQuery } from './query'
import { withSelect } from '../form.select.hoc'
import { StateProps } from '../../../state/form.state'
import { AddressSearch, AddressSearchVariables } from './__generated__/AddressSearch'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { captchaTokenAsContext } from '../../../utils/graphql-context.utils'
import { LinearProgress } from '@material-ui/core'
import { AddressLookup, AddressLookupVariables } from './__generated__/AddressLookup'
import debounce from 'lodash.debounce'
import omit from 'lodash.omit'

let open: boolean | undefined = undefined

const search = debounce(
    (
        postCode: string,
        executeRecaptcha: (action: string | undefined) => Promise<string>,
        findAddress: (options: QueryLazyOptions<AddressSearchVariables>) => void
    ) => {
        const getToken = async () => {
            const captchaToken = await executeRecaptcha('address_search')
            findAddress({
                ...captchaTokenAsContext(captchaToken),
                variables: {
                    postcode: postCode
                }
            })
        }
        getToken()
    },
    500
)

const AddressList = withSelect({
    fieldName: 'AddressLookupId',
    fieldDisplayName: 'Find address',
    items: []
})

const AddressSearchList = (props: StateProps) => {
    const { executeRecaptcha } = useGoogleReCaptcha()
    const client = useApolloClient()
    const [findAddress, { called, loading, data, error }] = useLazyQuery<AddressSearch, AddressSearchVariables>(
        AddressSearchQuery
    )

    const { state, dispatch } = props
    const postCode = state.PostCode || ''
    const postCodeErrors = state.errors.PostCode || { isValid: true, errors: [] }
    const lastChange = state.lastChange

    useMemo(() => {
        if (lastChange === 'PostCode' && executeRecaptcha && postCodeErrors.isValid) {
            search(postCode, executeRecaptcha, findAddress)
        }
    }, [executeRecaptcha, findAddress, lastChange, postCode, postCodeErrors.isValid])

    const disabled = (called && loading) || error !== undefined || !data || !data.addressSearch.results.length
    const items = !data ? [] : data.addressSearch.results.map(r => ({ name: r.address, value: r.id }))

    if (open) {
        open = false
    } else if (open === undefined && items.length > 0) {
        open = true
    }

    return (
        <>
            <AddressList
                {...props}
                items={items}
                open={open}
                disabled={disabled}
                onSelected={value => {
                    client
                        .query<AddressLookup, AddressLookupVariables>({
                            query: AddressLookupQuery,
                            variables: { lookupId: value }
                        })
                        .then(result => {
                            if (result.data.addressLookup) {
                                const state = {
                                    AddressLookupId: value,
                                    ...result.data.addressLookup,
                                    PostCode: result.data.addressLookup.Postcode,
                                    HouseNameFlat: result.data.addressLookup.HouseName || result.data.addressLookup.Flat
                                }

                                dispatch({
                                    type: 'address-lookup-complete',
                                    state: omit(state, 'Postcode', 'HouseName', 'Flat', '__typename')
                                })
                            }
                        })
                }}
            />
            {loading && <LinearProgress />}
        </>
    )
}

export { AddressSearchList }
