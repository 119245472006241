import React, {
    useMemo,
    Reducer,
    useReducer,
    createContext,
    useContext,
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction
} from 'react'
import {
    GetConfiguration_eligibilityConfiguration,
    GetConfiguration,
    GetConfigurationVariables
} from '../forms/calculator/__generated__/GetConfiguration'
import { useApolloClient } from '@apollo/react-hooks'
import { ApolloClient } from 'apollo-boost'
import { GetConfigurationQuery } from '../forms/calculator/query'
import { DynamicTheme } from './theme.state'
import { grey } from '@material-ui/core/colors'
import { palette as chrysalisPalette } from '../theme/palettes/chrysalis-palette'
import { SimplePaletteColorOptions } from '@material-ui/core'

export type RequestParams = GetConfigurationVariables & {
    treatmentcost?: number
    treatmentdescription?: string
    trackingdata?: string
}

export interface Config extends RequestParams {
    configuration: GetConfiguration_eligibilityConfiguration
    loaded: boolean
}

interface ConfigAction {
    type: 'config'
    state: Config
}

const ConfigContext = createContext<{ config: Config; dispatch: Dispatch<ConfigAction> } | undefined>(undefined)

const reducer: Reducer<Config, ConfigAction> = (state, action): Config => {
    // console.log('action:', action.type, action.state)
    switch (action.type) {
        case 'config':
            return { ...state, ...action.state }
        default:
            return state
    }
}

const ConfigProvider: FC<PropsWithChildren<{}>> = props => {
    const [config, dispatch] = useReducer(reducer, {
        loaded: false,
        retailerId: '',
        apikey: '',
        configuration: ({
            MaxDepositPC: 50,
            MinDepositPC: 10,
            EligibilityProducts: {
                RetailerCreditGroupValueBands: []
            }
        } as unknown) as GetConfiguration_eligibilityConfiguration
    })
    const value = useMemo(
        () => ({
            config,
            dispatch
        }),
        [config]
    )
    return <ConfigContext.Provider value={value} {...props} />
}

const loadConfig = (client: ApolloClient<{}>, retailerId: string, apikey: string) => {
    return client
        .query<GetConfiguration, GetConfigurationVariables>({
            query: GetConfigurationQuery,
            variables: { retailerId, apikey }
        })
        .then(result => result.data.eligibilityConfiguration)
        .catch(e => {
            console.error(`get config failed`)
            return undefined
        })
}

const useConfig = (requestParams?: RequestParams, updateTheme?: Dispatch<SetStateAction<DynamicTheme>>) => {
    const context = useContext(ConfigContext)
    if (!context) {
        throw new Error(`useConfig must be used within a ConfigProvider!`)
    }
    const { config, dispatch } = context

    const updateConfig = (config: Config) => {
        dispatch({
            type: 'config',
            state: config
        })
        if (updateTheme) {
            let palette = chrysalisPalette
            if (config.configuration.PrimaryColourPalette || config.configuration.SecondaryColourPalette) {
                const primary = {
                    ...palette.primary,
                    ...JSON.parse(config.configuration.PrimaryColourPalette || '{}')
                }
                const secondary = {
                    ...palette.secondary,
                    ...JSON.parse(config.configuration.SecondaryColourPalette || '{}')
                }

                palette = {
                    primary,
                    secondary
                }
            }
            updateTheme({ palette, isUpdated: true })
        }
    }

    const client = useApolloClient()

    if (!config.loaded && requestParams) {
        loadConfig(client, requestParams.retailerId, requestParams.apikey).then(
            configuration =>
                configuration &&
                updateConfig({
                    loaded: true,
                    ...requestParams,
                    configuration
                })
        )
    }

    return {
        config,
        updateConfig
    }
}

export { useConfig, ConfigProvider }
