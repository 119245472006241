import React from 'react'
import { Grid } from '@material-ui/core'

const Layout = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                Header 2 (for Amber/Green results)
            </Grid>
            <Grid item xs={12} sm={6}>
                Left
            </Grid>
            <Grid item xs={12} sm={6}>
                Right
            </Grid>
            <Grid item xs={12}>
                Full
            </Grid>
            <Grid item xs={12}>
                Stepper
            </Grid>
        </Grid>
    )
}

export { Layout as LayoutTestTwo }
