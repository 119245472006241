import React, { useMemo, Reducer, useReducer, createContext, useContext, Dispatch, FC, PropsWithChildren } from 'react'
import { FinanceCalculator } from '../service/calculator'
import { useConfig } from './config.state'
import { findBestProduct } from '../service/product.finder'

export interface Loan {
    treatmentCost?: number
    deposit?: number
    loanAmount?: number
    term?: number
    monthlyAmount?: number
    apr?: number
    totalCost?: number
}

export interface LoanState {
    detail: Loan
    product?: FinanceCalculator
}

interface LoanStateAction {
    type: 'update'
    state: LoanState
}

const LoanStateContext = createContext<{ loan: LoanState; dispatch: Dispatch<LoanStateAction> } | undefined>(undefined)

const reducer: Reducer<LoanState, LoanStateAction> = (state, action): LoanState => {
    // console.log('loan state action:', action.type, action.state)
    switch (action.type) {
        case 'update':
            return { ...state, ...action.state }
        default:
            return state
    }
}

const LoanStateProvider: FC<PropsWithChildren<{}>> = props => {
    const [loan, dispatch] = useReducer(reducer, {
        detail: {}
    })
    const value = useMemo(
        () => ({
            loan,
            dispatch
        }),
        [loan]
    )
    return <LoanStateContext.Provider value={value} {...props} />
}

const useLoanState = () => {
    const context = useContext(LoanStateContext)
    if (!context) {
        throw new Error(`useLoanState must be used within a LoanStateProvider!`)
    }
    const { config } = useConfig()
    const { loan, dispatch } = context

    const updateLoan = (detail: Loan) => {
        let product
        if (config.loaded) {
            product = findBestProduct(
                config.configuration.EligibilityProducts.RetailerCreditGroupValueBands,
                detail.loanAmount || 0,
                detail.term
            )
        }

        return dispatch({
            type: 'update',
            state: { detail, product }
        })
    }

    return {
        loan,
        updateLoan
    }
}

export { useLoanState, LoanStateProvider }
