import React, { useReducer, createContext, Dispatch, PropsWithChildren, FC, useMemo, useContext } from 'react'
import { EligibilityFormFields, FieldErrors } from '../forms/eligibility-checker/form.types'
import omit from 'lodash.omit'
import { validateField } from '../forms/eligibility-checker/form.validation'
import moment from 'moment'

export interface EligibilityForm extends EligibilityFormFields {
    isDirty: boolean
    errors: FieldErrors
    lastChange?: keyof EligibilityFormFields
}

export interface Action {
    type: 'reset' | 'update' | 'address-lookup-complete' | 'errors'
    state: Partial<EligibilityForm>
}

export interface StateProps {
    dispatch: React.Dispatch<Action>
    state: EligibilityForm
}

const omitNonFields = (state: Partial<EligibilityForm>) => omit(state, 'errors', 'isDirty', 'lastChange')

const defaultGender = (action: Action) => {
    if (action.state.Title) {
        if (['Ms', 'Miss', 'Mrs'].includes(action.state.Title)) return 'F'
        else if (['Mr'].includes(action.state.Title)) return 'M'
    }
    return undefined
}

const FormStateContext = createContext<{ form: EligibilityForm; dispatch: Dispatch<Action> } | undefined>(undefined)

const reducer = (state: EligibilityForm, action: Action): EligibilityForm => {
    // console.log(action.type + ' -->', action.state, '-->', state)
    switch (action.type) {
        case 'update':
            if (action.state.Title && !state.Gender) {
                action.state.Gender = defaultGender(action)
            }
            const currentChange = Object.keys(omitNonFields(action.state)) as [keyof EligibilityFormFields]
            const lastChange = currentChange && currentChange.length ? currentChange[0] : undefined
            let errors = state.errors
            if (lastChange) {
                errors = Object.assign(errors, validateField(lastChange, action.state[lastChange]))
            }
            const nextState: EligibilityForm = { ...state, ...action.state, lastChange, errors }
            const isDirty = Object.keys(nextState.errors)
                .map(f => {
                    const field = nextState.errors[f as keyof EligibilityFormFields]
                    return field ? field.isValid : true
                })
                .includes(false)

            nextState.isDirty = isDirty
            return nextState
        case 'address-lookup-complete':
            return {
                ...state,
                errors: omit(state.errors, 'HouseNumber', 'HouseNameFlat', 'Street', 'Town', 'County', 'PostCode'),
                ...action.state
            }
        case 'errors':
            return { ...state, ...action.state }
        case 'reset':
            return { ...action.state, errors: {}, isDirty: true }
    }
}

const FormStateProvider: FC<PropsWithChildren<{}>> = props => {
    const [form, dispatch] = useReducer(reducer, {
        // DOB: moment()
        //     .subtract(18, 'years')
        //     .format('DD/MM/YYYY'),
        errors: {},
        isDirty: true
    })
    const value = useMemo(
        () => ({
            form,
            dispatch
        }),
        [form]
    )
    return <FormStateContext.Provider value={value} {...props} />
}

const useEligibilityForm = () => {
    const context = useContext(FormStateContext)
    if (!context) {
        throw new Error(`useEligibilityForm must be used within a FormStateProvider!`)
    }
    const { form, dispatch } = context
    return {
        state: form,
        dispatch
    }
}

export { useEligibilityForm, FormStateProvider, omitNonFields }
