import gql from 'graphql-tag'

const GetConfigurationQuery = gql`
    query GetConfiguration($retailerId: String!, $apikey: String!) {
        eligibilityConfiguration(retailerId: $retailerId, apikey: $apikey) {
            RetailerId
            Name
            MinDepositPC
            MaxDepositPC
            ShowRetailerLogo
            RetailerLogoUrl
            ShowChrysalisLogo
            ChrysalisLogoUrl
            ThemeId
            PrimaryColourPalette
            SecondaryColourPalette
            EligibilityProducts {
                RetailerCreditGroupValueBands {
                    ValueFrom
                    ValueTo
                    APR
                    DisplayAPR
                    Term
                    CreditGroupId
                    SchemeCode
                    FinanceCoId
                }
            }
        }
    }
`

export { GetConfigurationQuery }
