import { PaletteOptions } from '@material-ui/core/styles/createPalette'

const palette: PaletteOptions = {
    primary: {
        '50': '#e4f2fd',
        '100': '#bcdffa',
        '200': '#92cbf7',
        '300': '#69b7f3',
        '400': '#49a7f2',
        '500': '#2d98f0',
        '600': '#298ae2',
        '700': '#2279cf',
        '800': '#1d68bd',
        '900': '#134a9e',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
        main: '#2278cf',
        light: '#69b7f3',
        dark: '#2279cf',
        contrastText: '#fff'
    },
    secondary: {
        '50': '#fae3f1',
        '100': '#f2b9dd',
        '200': '#e989c7',
        '300': '#de54b2',
        '400': '#d418a2',
        '500': '#c90094',
        '600': '#bb0090',
        '700': '#a9008b',
        '800': '#990085',
        '900': '#7c007c',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
        main: '#d418a2',
        light: '#aaaaaa',
        dark: '#616161',
        contrastText: '#fff'
    },
    background: {
        default: '#ffffff'
    },
    common: {
        black: '#000000',
        white: '#ffffff'
    }
}

export { palette }
