import React, { ComponentType } from 'react'
import { KeyboardDatePickerProps } from '@material-ui/pickers'
import omit from 'lodash.omit'
import moment, { Moment } from 'moment'

import { StateProps } from '../../state/form.state'
import { EligibilityFormFields } from './form.types'

interface DefaultPickerProps extends Partial<KeyboardDatePickerProps> {
    fieldName: keyof EligibilityFormFields
    fieldDisplayName?: string
}

const withDatePicker = <P extends {}>(Component: ComponentType<P>, defaultProps: DefaultPickerProps) => {
    const { fieldName, label, required = true, readOnly = false, inputProps = {}, ...rest } = defaultProps

    const WithDatePicker = (props: StateProps & Partial<P>) => {
        const mergedInputProps = { ...inputProps, readOnly }
        const { dispatch, state } = props

        const currentValue = state[fieldName]
        const currentErrors = state.errors[fieldName]
        const helperText = currentErrors && !currentErrors.isValid ? currentErrors.errors[0].message : ''

        return (
            <Component
                id={fieldName}
                value={(currentValue && moment(currentValue, 'DD/MM/YYYY').toDate()) || null}
                helperText={helperText}
                error={helperText !== ''}
                label={label}
                autoOk
                disableFuture
                openTo="date"
                views={['date']}
                variant="inline"
                inputVariant="outlined"
                format="DD/MM/YYYY"
                InputAdornmentProps={{ position: 'start' }}
                onChange={(date: Moment) => {
                    if (date && date.isValid()) {
                        dispatch({
                            type: 'update',
                            state: { [fieldName]: date.format('DD/MM/YYYY') }
                        })
                    }
                }}
                inputProps={mergedInputProps}
                required={required}
                {...(({ ...rest, ...omit(props, 'state', 'dispatch') } as unknown) as Required<P>)}
            />
        )
    }
    return WithDatePicker
}

export { withDatePicker }
