import React from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { Container } from '@material-ui/core'
import { primaryColor } from '../theme/utils'

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            // backgroundColor: '#E9EFF4'
            backgroundColor: primaryColor(theme)[50]
        },
        backButton: {
            marginRight: theme.spacing(1)
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        stepperContainer: {
            // backgroundColor: '#E9EFF4'
            backgroundColor: primaryColor(theme)[50]
        }
    })
)

function getSteps() {
    return ['Customise your  plan', 'Check your eligibility', 'View your results']
}

function getStepContent(stepIndex: number) {
    switch (stepIndex) {
        case 0:
            return 'Select campaign settings...'
        case 1:
            return 'What is an ad group anyways?'
        case 2:
            return 'This is the bit I really care about!'
        default:
            return 'Unknown stepIndex'
    }
}
interface Props {
    position?: number
}
const EligibilityStepper = ({ position = 0 }: Props) => {
    const classes = useStyles()
    const [activeStep, setActiveStep] = React.useState(position)
    const steps = getSteps()

    const handleNext = () => {
        setActiveStep(prevActiveStep => prevActiveStep + 1)
    }

    const handleBack = () => {
        setActiveStep(prevActiveStep => prevActiveStep - 1)
    }

    const handleReset = () => {
        setActiveStep(0)
    }

    return (
        <div className={classes.root}>
            <Container fixed maxWidth="md">
                <Stepper activeStep={activeStep} alternativeLabel className={classes.stepperContainer}>
                    {steps.map(label => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
            </Container>
        </div>
    )
}

export { EligibilityStepper }
