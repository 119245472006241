import React, { FC, createContext, Dispatch, SetStateAction, useContext, PropsWithChildren, useState } from 'react'

import { PaletteOptions } from '@material-ui/core/styles/createPalette'

export interface DynamicTheme {
    palette?: PaletteOptions
    isUpdated?: boolean
}
export interface DynamicThemeState {
    theme: DynamicTheme
    updateTheme: Dispatch<SetStateAction<DynamicTheme>>
}

const useDynamicTheme = () => {
    return useContext(DynamicThemeContext)
}

const DynamicThemeProvider: FC = ({ children }: PropsWithChildren<{}>) => {
    const [theme, updateTheme] = useState<DynamicTheme>({})

    return <DynamicThemeContext.Provider value={{ theme, updateTheme }}>{children}</DynamicThemeContext.Provider>
}

const DynamicThemeContext = createContext<DynamicThemeState>({} as DynamicThemeState)

export { useDynamicTheme, DynamicThemeProvider, DynamicThemeContext }
