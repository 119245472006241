import { BigNumber } from 'bignumber.js'
import { GetConfiguration_eligibilityConfiguration_EligibilityProducts_RetailerCreditGroupValueBands as FinanceProduct } from '../forms/calculator/__generated__/GetConfiguration'

const isInterestFreeOptionProduct = (product: FinanceProduct) => {
    return product.SchemeCode.substring(0, 2) === 'CO'
}

const isInterestFreeProduct = (product: FinanceProduct) => {
    return product.APR === 0
}

class FinanceCalculator {
    public product: FinanceProduct
    public monthlyPayment: number
    public interestFree: boolean
    public totalAmountPayable: string
    public description: string
    public ifo: boolean
    public optionTerm?: number
    public monthlyPaymentValue: string
    public totalAmtPayable?: number
    public optionFinalPayment?: number

    public constructor(product: FinanceProduct) {
        this.product = product
        this.monthlyPayment = 0.0
        this.interestFree = isInterestFreeProduct(product)
        this.totalAmountPayable = '0.00'
        this.monthlyPaymentValue = '0.00'

        if (isInterestFreeOptionProduct(product)) {
            this.description = ''
            this.ifo = true
            this.optionTerm = parseInt(product.SchemeCode.substring(2, 4))
        } else {
            this.description = product.Term + ' Months(' + product.DisplayAPR + '%)'
            this.ifo = false
        }
    }

    public calculatePrice(amount: number) {
        let monthlyPayment: BigNumber
        if (!this.interestFree) {
            var intr = this.product.APR / 1200
            var tmonth1 = amount * intr
            var tmonth2 = 1 - Math.pow(1 / (1 + intr), this.product.Term)
            monthlyPayment = new BigNumber(tmonth1).dividedBy(new BigNumber(tmonth2))
            this.totalAmountPayable = (parseFloat(monthlyPayment.toFixed(2)) * this.product.Term).toFixed(2)
            this.totalAmtPayable = parseFloat(monthlyPayment.toFixed(2)) * this.product.Term
        } else {
            const a = new BigNumber(amount)
            monthlyPayment = a.dividedBy(this.product.Term).decimalPlaces(2, BigNumber.ROUND_DOWN)
            this.totalAmountPayable = amount.toFixed(2)
            this.totalAmtPayable = amount
        }
        this.monthlyPaymentValue = monthlyPayment.toFixed(2)
        this.monthlyPayment = monthlyPayment.toNumber()
        if (this.ifo && this.optionTerm) {
            this.optionFinalPayment = amount - (this.optionTerm - 1) * monthlyPayment.toNumber()
        }

        return this.monthlyPayment
    }
}

export { FinanceCalculator }
