import { colors } from '@material-ui/core'
import { PaletteOptions } from '@material-ui/core/styles/createPalette'
import { grey, pink } from '@material-ui/core/colors'

const palette: PaletteOptions = {
    primary: {
        ...grey,
        main: colors.grey[200]
    },
    secondary: {
        ...pink
        // main: colors.blueGrey[100]
    },
    background: {
        default: '#ffffff'
    },
    common: {
        black: '#000000',
        white: '#ffffff'
    }
}

export { palette }
