import React from 'react'
import { Slider, withStyles } from '@material-ui/core'
import { primaryColor } from '../theme/utils'

const LoanAmountSlider = withStyles(
    theme => ({
        valueLabel: {
            width: 75,
            height: 34,
            backgroundColor: primaryColor(theme)[500],
            transform: 'none',
            fontSize: 16,
            minWidth: 30,

            '& > span': {
                backgroundColor: 'transparent'
            }
        },

        thumb: {
            backgroundColor: primaryColor(theme)[500],
            position: 'relative',

            '&:before': {
                top: -12,
                content: '""',
                position: 'absolute',
                width: 0,
                height: 0,
                borderWidth: 10,
                borderStyle: 'solid',
                borderTopColor: primaryColor(theme)[500],
                borderBottomColor: 'transparent',
                borderRightColor: 'transparent',
                borderLeftColor: 'transparent'
            }
        },

        track: {
            color: primaryColor(theme)[500],
            height: 3
        },
        rail: {
            height: 3
        },
        mark: {
            height: 3
        }
    }),
    { withTheme: true }
)(Slider)

export { LoanAmountSlider }
