import { Theme, Color } from '@material-ui/core'

const primaryColor = (theme: Theme): Color => {
    return (theme.palette.primary as unknown) as Color
}

const secondaryColor = (theme: Theme): Color => {
    return (theme.palette.secondary as unknown) as Color
}

export { primaryColor, secondaryColor }
