import React, { FC } from 'react'
import {
    Grid,
    InputAdornment,
    OutlinedInput,
    Typography,
    makeStyles,
    createStyles,
    Box,
    FormControl,
    InputLabel
} from '@material-ui/core'
import { BigNumber } from 'bignumber.js'

import { useConfig } from '../../state/config.state'
import { useLoanState } from '../../state/loan.state'
import { LoanAmountSlider } from '../../components/LoanAmountSlider'
import { DepositAmountSlider } from '../../components/DepositAmountSlider'
import { LoanPeriodSlider } from '../../components/LoanPeriodSlider'

const useStyles = makeStyles(theme =>
    createStyles({
        consFee: {
            fontWeight: theme.typography.fontWeightLight,
            fontSize: theme.typography.pxToRem(15),
            paddingTop: theme.spacing(4),
            paddingLeft: theme.spacing(1)
        },
        sliderContainer: {
            paddingLeft: theme.spacing(0.5),
            paddingRight: theme.spacing(3)
        },
        tp: {
            paddingTop: 5
        },

        estTreatmentCost: {
            fontSize: 24,
            color: theme.palette.primary.main
        },
        labelBottom: {
            paddingBottom: theme.spacing(4)
        }
    })
)

const calcDeposit = (treamentPrice: number, depositPercentage: number) => {
    if (depositPercentage === 0) return 0
    return new BigNumber(treamentPrice * (depositPercentage / 100)).decimalPlaces(0, BigNumber.ROUND_DOWN).toNumber()
}

const calcLoanMarks = (amount: number) => {
    if (amount === 0) return []
    const step = amount / 3
    if (step < 10) return []
    const marks = [0, 1, 2, 3].map(i => {
        const stepAmount = new BigNumber(i * step).decimalPlaces(0, BigNumber.ROUND_DOWN).toNumber()
        return { value: stepAmount, label: `£${new BigNumber(stepAmount).toFormat()}` }
    })
    return marks
}

const calcDepositMarks = (amount: number) => {
    if (amount === 0) return []
    const step = amount / 4
    const marks = [0, 1, 2, 3, 4].map(i => {
        const stepAmount = new BigNumber(i * step).decimalPlaces(0, BigNumber.ROUND_DOWN).toNumber()
        return { value: stepAmount, label: `£${new BigNumber(stepAmount).toFormat()}` }
    })
    return marks
}

const gbpValueFormatter = (v: number) => {
    const formatted = new BigNumber(v).toFormat()
    return <div style={{ width: '135%', textAlign: 'right' }}>&pound;{formatted}</div>
}

const loanPeriodFormatter = (v: number) => {
    return <div style={{ width: '135%', textAlign: 'right' }}>{v}</div>
}

const LoanPanel: FC = () => {
    const classes = useStyles()
    const { config } = useConfig()
    const { MinDepositPC, MaxDepositPC, EligibilityProducts } = config.configuration

    const {
        loan: { detail },
        updateLoan
    } = useLoanState()

    const treatmentPrice = detail.treatmentCost || config.treatmentcost || 10000
    const minDeposit = calcDeposit(treatmentPrice, MinDepositPC)
    const maxDeposit = calcDeposit(treatmentPrice, MaxDepositPC)
    const deposit = detail.deposit || minDeposit
    const loanAmount = detail.loanAmount || treatmentPrice - minDeposit
    const products = EligibilityProducts.RetailerCreditGroupValueBands.filter(
        p => treatmentPrice >= p.ValueFrom && treatmentPrice <= p.ValueTo
    )
    const productTerms = products.map(v => v.Term)
    const loanPeriodMarks = productTerms
        .map(t => ({ value: t, label: t + '' }))
        .sort((a, b) => {
            return a.value - b.value
        })

    let term = detail.term || (productTerms.length ? productTerms[0] : 0)
    if (term === 0) term = loanPeriodMarks.length ? loanPeriodMarks[0].value : 0

    const minTerm = loanPeriodMarks.length ? loanPeriodMarks[0].value : 0
    const maxTerm = loanPeriodMarks.length ? loanPeriodMarks[loanPeriodMarks.length - 1].value : 0
    const depositMarks = calcDepositMarks(maxDeposit)
    const loanMarks = calcLoanMarks(treatmentPrice)

    const loan = { term, deposit, loanAmount, treatmentCost: treatmentPrice }

    if (detail.term === undefined && loan.term !== 0) {
        updateLoan(loan)
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="price">Your estimated treatment cost</InputLabel>
                            <OutlinedInput
                                id="price"
                                type="number"
                                labelWidth={220}
                                value={treatmentPrice}
                                disabled={config.treatmentcost !== undefined}
                                onChange={e => {
                                    const amount = parseInt(e.currentTarget.value)
                                    if (!isNaN(amount)) {
                                        const deposit = calcDeposit(amount, MinDepositPC)

                                        updateLoan({
                                            ...loan,
                                            treatmentCost: amount,
                                            deposit,
                                            loanAmount: amount - deposit
                                        })
                                    } else {
                                        updateLoan({
                                            ...loan,
                                            treatmentCost: 0,
                                            loanAmount: 0,
                                            deposit: 0
                                        })
                                    }
                                }}
                                startAdornment={<InputAdornment position="start">£</InputAdornment>}
                                className={classes.estTreatmentCost}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography className={classes.consFee}>(excluding consultation fee)</Typography>
                    </Grid>
                </Grid>
                <Box mb={5} />
                <Grid container>
                    <Grid item xs={12} className={classes.sliderContainer}>
                        <Typography className={classes.labelBottom} gutterBottom>
                            Deposit:
                        </Typography>
                        <DepositAmountSlider
                            defaultValue={deposit}
                            value={deposit}
                            step={1}
                            min={0}
                            max={maxDeposit}
                            onChange={(e, v) => {
                                const n = v as number
                                if (n <= minDeposit) {
                                    updateLoan({
                                        ...loan,
                                        deposit: minDeposit,
                                        loanAmount: treatmentPrice - minDeposit
                                    })
                                } else {
                                    updateLoan({
                                        ...loan,
                                        deposit: n,
                                        loanAmount: treatmentPrice - n
                                    })
                                }
                            }}
                            marks={depositMarks}
                            valueLabelDisplay="on"
                            valueLabelFormat={gbpValueFormatter}
                        />
                    </Grid>
                </Grid>
                <Box mb={5} />
                <Grid container>
                    <Grid item xs={12} className={classes.sliderContainer}>
                        <Typography className={classes.labelBottom} gutterBottom>
                            Loan Amount:
                        </Typography>
                        <LoanAmountSlider
                            defaultValue={loanAmount}
                            value={loanAmount}
                            step={1}
                            min={0}
                            max={treatmentPrice}
                            onChange={(e, v) => {
                                const n = v as number
                                if (n < treatmentPrice - maxDeposit) {
                                    updateLoan({
                                        ...loan,
                                        deposit: treatmentPrice - maxDeposit,
                                        loanAmount: treatmentPrice - maxDeposit
                                    })
                                } else if (n !== loanAmount) {
                                    updateLoan({
                                        ...loan,
                                        deposit: treatmentPrice - n,
                                        loanAmount: n
                                    })
                                }
                            }}
                            marks={loanMarks}
                            valueLabelDisplay="on"
                            valueLabelFormat={gbpValueFormatter}
                        />
                    </Grid>
                </Grid>
                <Box mb={5} />
                <Grid container>
                    <Grid item xs={12} className={classes.sliderContainer}>
                        <Typography className={classes.labelBottom} gutterBottom>
                            Loan period (months):
                        </Typography>
                        <LoanPeriodSlider
                            value={term}
                            aria-labelledby="discrete-slider-restrict3"
                            step={null}
                            min={minTerm}
                            max={maxTerm}
                            onChange={(e, v) => {
                                const n = v as number
                                updateLoan({
                                    ...loan,
                                    term: n
                                })
                            }}
                            marks={loanPeriodMarks}
                            valueLabelDisplay="on"
                            valueLabelFormat={loanPeriodFormatter}
                        />
                    </Grid>
                </Grid>
                <Box mb={3} />
            </Grid>
        </Grid>
    )
}

export { LoanPanel }
