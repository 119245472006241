import React from 'react'
import { Grid, Hidden } from '@material-ui/core'

const LayoutTest = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                Header (for Stage 1/2)
            </Grid>
            <Grid item xs={12} sm={8}>
                Left
            </Grid>
            <Grid item xs={12} sm={4}>
                Right
            </Grid>
            <Grid item xs={12}>
                Stepper
            </Grid>
        </Grid>
    )
}

export { LayoutTest }
