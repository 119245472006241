import React from 'react'
import { BigNumber } from 'bignumber.js'

import {
    Grid,
    Paper,
    makeStyles,
    createStyles,
    Typography,
    useTheme,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core'
import { FinanceCalculator } from '../service/calculator'
import { Loan } from '../state/loan.state'

interface Props {
    detail: Loan
    product?: FinanceCalculator
}

const useStyles = makeStyles(theme => {
    return createStyles({
        planSummary: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            fontWeight: theme.typography.fontWeightBold,
            textAlign: 'center',
            borderTopLeftRadius: 10,
            borderBottomLeftRadius: 10,
            padding: theme.spacing(2, 1, 4, 1),

            '& h4': {
                fontSize: '1.2rem'
            },

            '& *': {
                color: theme.palette.primary.contrastText
            },

            '& rateText': {
                fontSize: 1
            },
            [theme.breakpoints.only('xs')]: {
                borderTopRightRadius: 10,
                borderBottomLeftRadius: 0
            }
        },

        planData: {
            borderTopRightRadius: 10,
            borderBottomRightRadius: 10,
            borderColor: theme.palette.primary.main,
            borderStyle: 'solid',
            borderWidth: 1,
            [theme.breakpoints.only('xs')]: {
                borderBottomLeftRadius: 10,
                borderTopRightRadius: 0
            }
        }
    })
})

const PaymentPlan = (props: Props) => {
    const classes = useStyles()
    const { detail, product } = props

    if (!product) {
        return <>no product details</>
    }

    return (
        <Grid container>
            <Grid item xs={12} sm={6} md={6} className={classes.planSummary}>
                <h4>PROPOSED PAYMENT PLAN</h4>
                <Typography variant="h3" display="inline">
                    &pound;{product.monthlyPaymentValue}
                </Typography>
                <span className="rateText">/mo</span>

                <h5>TREATMENT PRICE</h5>
                <Typography variant="h5">&pound;{new BigNumber(detail.treatmentCost || 0).toFormat(2)}</Typography>
                <Typography>(excluding consultation fee)</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} className={classes.planData}>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography color="primary">Loan amount:</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography align="right">
                                    &pound;{new BigNumber(detail.loanAmount || 0).toFormat(2)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography color="primary">Deposit:</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography align="right">
                                    &pound;{new BigNumber(detail.deposit || 0).toFormat(2)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography color="primary">Loan duration:</Typography>
                            </TableCell>
                            <TableCell>
                                {' '}
                                <Typography align="right">{detail.term} months</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                {' '}
                                <Typography color="primary">APR:</Typography>
                            </TableCell>
                            <TableCell>
                                {' '}
                                <Typography align="right"> {product.product.DisplayAPR}%</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography color="primary">Total to repay:</Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography>
                                    &pound;{new BigNumber(product.totalAmtPayable || 0).toFormat(2)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography color="primary">Total cost:</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography align="right">
                                    &pound;
                                    {new BigNumber((product.totalAmtPayable || 0) + (detail.deposit || 0)).toFormat(2)}
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    )
}

export { PaymentPlan }
