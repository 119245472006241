import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'

import { typography } from './typography'
import { overrides } from './overrides'
import { palette } from './palettes/monochrome-palette'
// import { palette } from './palettes/chrysalis-palette'

const BaseTheme = responsiveFontSizes(
    createMuiTheme({
        palette,
        overrides,
        typography
    })
)

// console.log(JSON.stringify(BaseTheme.palette.primary))
// console.log(JSON.stringify(BaseTheme.palette.secondary))

export { BaseTheme }
