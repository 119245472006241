import React from 'react'
import { Grid } from '@material-ui/core'

const Layout = () => {
    return (
        <Grid container>
            <Grid item xs={12}>
                Header 3 (for Red result)
            </Grid>
            <Grid item xs={12}>
                Main Content
            </Grid>
            <Grid item xs={12}>
                Stepper
            </Grid>
        </Grid>
    )
}

export { Layout as LayoutTestThree }
