import { EligibilityForm, omitNonFields } from '../../state/form.state'
import { EligibilityInput } from '../../__generated__/globalTypes'
import moment from 'moment'
import { GetConfiguration_eligibilityConfiguration } from '../calculator/__generated__/GetConfiguration'
import { Config } from '../../state/config.state'
import { LoanState } from '../../state/loan.state'
import { formattedNumber } from '../../utils/number.utils'
import { validateAllFields } from './form.validation'
import { FieldErrors } from './form.types'

export class ValidationErrors extends Error {
    public errors: FieldErrors
    public constructor(errors: FieldErrors) {
        super('Field errors')

        this.errors = errors
    }
}

const validateForm = (config: Config, loanState: LoanState, form: EligibilityForm): Promise<EligibilityInput> => {
    if (!form.AddressLookupId) form.AddressLookupId = 'submit'

    const result = validateAllFields(form)
    if (Object.keys(result).length > 0) {
        return Promise.reject(new ValidationErrors(result))
    }

    const { configuration } = config
    const { detail, product } = loanState
    if (!product) return Promise.reject('no product selected')

    const cleanForm = { ...omitNonFields(form) } as Required<EligibilityForm>
    delete cleanForm['DOB']
    delete cleanForm['AddressLookupId']

    const dob = moment(form.DOB, 'DD/MM/YYYY')

    const data: EligibilityInput = {
        RetailerId: configuration.RetailerId + '',
        APIkey: config.apikey || '',
        TreatmentDescription: config.treatmentdescription || '',
        TrackingData: config.trackingdata || '',
        ...cleanForm,
        DOBdd: dob.date(),
        DOBmm: dob.month() + 1,
        DOByyyy: dob.year(),
        TreatmentCost: formattedNumber(detail.treatmentCost),
        Deposit: formattedNumber(detail.deposit),
        LoanAmount: formattedNumber(detail.loanAmount),
        FinanceTerm: formattedNumber(detail.term, 0),
        FinanceAPR: formattedNumber(product.product.DisplayAPR, 1),
        MonthlyInstallment: product.monthlyPaymentValue,
        TotalAmountPayable: product.totalAmountPayable,
        FinanceProductCode: product.product.CreditGroupId,
        ChargeForCredit: '0.00', // wtf?
        GrossAnnualIncome: '',
        EmplymentStatus: '',
        ResidentialStatus: '',
        AppointmentYYYY: '',
        AppointmentMM: '',
        AppointmentDD: '',
        Address2: '',
        Address3: ''
    }

    return Promise.resolve(data)
}

export { validateForm }
