import { ValidationError, FieldValidator } from './form.types'
import moment from 'moment'
import { maxDob, minDob } from './form.validation'

const asValidationError = (errors: ValidationError[]) => {
    return {
        isValid: errors.length === 0,
        errors
    }
}

const lengthValidator = (name: string, minLength?: number, maxLength?: number): FieldValidator => {
    return (value: string) => {
        const errors: ValidationError[] = []
        if (minLength && value.length < minLength) {
            errors.push({ message: `${name} must have atleast ${minLength} chars` })
        } else if (value && maxLength && value.length > maxLength) {
            errors.push({ message: `${name} must have less than ${maxLength} chars` })
        }

        return asValidationError(errors)
    }
}

const requiredValidator = (name: string): FieldValidator => {
    return (value: string) => {
        const errors: ValidationError[] = []
        if (!value || !value.length) {
            errors.push({ message: `${name} is required` })
        }
        return asValidationError(errors)
    }
}

const dobValidator = (name: string): FieldValidator => {
    return (value: string) => {
        const errors: ValidationError[] = []
        if (value) {
            const dob = moment(value, 'DD/MM/YYYY')
            if (!dob.isValid() || !dob.isBetween(minDob, maxDob)) {
                errors.push({ message: `Please enter a valid ${name}` })
            }
        }
        return asValidationError(errors)
    }
}

const emailValidator = (name: string): FieldValidator => {
    return (value: string) => {
        const errors: ValidationError[] = []
        if (
            !/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+.)+([a-zA-Z0-9]{2,4})+$/.test(value) ||
            /enquires@|enquiry@|info@/g.test(value)
        ) {
            errors.push({ message: `${name} must be a valid personal email address` })
        }
        return asValidationError(errors)
    }
}

const phoneNumberValidator = (name: string): FieldValidator => {
    return (value: string) => {
        const errors: ValidationError[] = []
        if (!/^0[ 0-9]{10,}/.test(value)) {
            errors.push({ message: `${name} must be a valid phone number` })
        }
        return asValidationError(errors)
    }
}

export { lengthValidator, requiredValidator, emailValidator, phoneNumberValidator, dobValidator }
