import React, { FC } from 'react'
import {
    Grid,
    makeStyles,
    createStyles,
    Box,
    Typography,
    Divider,
    Button,
    Table,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core'
import { findBestProduct } from '../../service/product.finder'
import { BigNumber } from 'bignumber.js'

import { DonutChart } from './Donut'
import { GetConfiguration_eligibilityConfiguration } from './__generated__/GetConfiguration'
import { useHistory } from 'react-router'
import { FinanceCalculator } from '../../service/calculator'
import { Loan, useLoanState } from '../../state/loan.state'
import { primaryColor } from '../../theme/utils'

const useStyles = makeStyles(theme =>
    createStyles({
        quotePanel: {
            borderRadius: 10,
            borderColor: theme.palette.primary.main,
            borderStyle: 'solid',
            borderWidth: 1
        },

        loanLabel: {
            '&:before': {
                content: '""',
                width: 16,
                height: 16,
                backgroundColor: theme.palette.primary.main,
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: 8
            }
        },
        depositLabel: {
            '&:before': {
                content: '""',
                width: 16,
                height: 16,
                backgroundColor: primaryColor(theme)[100],
                borderRadius: '50%',
                display: 'inline-block',
                marginRight: 8
            }
        },
        interestBox: {
            width: '10%',
            height: 30,
            backgroundColor: theme.palette.primary.dark,
            '& *': {
                paddingTop: 5,
                color: theme.palette.secondary.contrastText
            }
        },

        donut: {
            '& svg': {
                maxWidth: 300,
                margin: '0 auto'
            }
        }
    })
)

interface Props {
    loan?: Loan
    configuration: GetConfiguration_eligibilityConfiguration
    product?: FinanceCalculator
}

const QuotePanel: FC<Props> = (props: Props) => {
    const classes = useStyles()
    const {
        loan: { detail, product }
    } = useLoanState()
    const loan = detail
    const { term } = loan
    const interest = product ? (product.totalAmtPayable || 0) - (loan.loanAmount || 0) : 0
    const apr = product && product.product.DisplayAPR
    const total = product && new BigNumber(product.totalAmtPayable || 0).toFormat(2)
    return (
        <Grid container spacing={0} justify="center" className={classes.quotePanel}>
            <Grid item xs={12} sm={6} md={12} className={classes.donut}>
                {product && (
                    <DonutChart
                        monthlyPayment={product.monthlyPaymentValue || ''}
                        loanAmount={loan.loanAmount || 0}
                        depositAmount={loan.deposit || 0}
                        interestPayable={interest}
                    />
                )}
            </Grid>
            <Grid item xs={12} sm={6} md={12}>
                <Grid container>
                    <Grid item xs={3} sm={4} md={4}></Grid>
                    <Grid item xs={6} sm={4} md={4} className={classes.loanLabel}>
                        <Typography display="inline">Loan</Typography>
                    </Grid>
                    <Grid item xs={3} sm={4} md={4}></Grid>
                    <Grid item xs={3} sm={4} md={4}></Grid>
                    <Grid item xs={6} sm={4} md={4} className={classes.depositLabel}>
                        <Typography display="inline">Deposit</Typography>
                    </Grid>
                    <Grid item xs={3} sm={4} md={4}></Grid>
                    {/* {interest > 0 && <Box className={classes.interestBox}></Box>} */}
                </Grid>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell>
                                <Typography color="primary">Loan period:</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography align="right">{term} months</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography color="primary">APR:</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography align="right">{apr}%</Typography>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>
                                <Typography color="primary">Total to repay:</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography align="right">&pound;{total}</Typography>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Grid container justify="center" spacing={2}>
                    <Grid item>
                        <Typography variant="caption" color="textSecondary">
                            Representitive example*
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export { QuotePanel }
