import React from 'react'
import { Switch, Route, useLocation } from 'react-router'

import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import queryString from 'query-string'

import { Calculator } from './forms/calculator/Calculator'
import { EligibilityChecker } from './forms/eligibility-checker/EligibilityChecker'
import { Eligible } from './results/Eligible'
import {
    Grid,
    createStyles,
    makeStyles,
    Backdrop,
    CircularProgress,
    Container,
    Typography,
    Link
} from '@material-ui/core'
import { EligibleToApply } from './results/EligibleToApply'
import { NotEligible } from './results/NotEligible'

import './App.css'
import { LayoutTest } from './LayoutTest'
import { LayoutTestTwo } from './LayoutTestTwo'
import { LayoutTestThree } from './LayoutTestThree'
import { useConfig, RequestParams } from './state/config.state'
import { LoanStateProvider } from './state/loan.state'
import { FormStateProvider } from './state/form.state'
import { useDynamicTheme } from './state/theme.state'
import { combineClassNames, useStylesBase } from './styles/shared-styles'

const sitekey = process.env.REACT_APP_RECAPTCHA_SITEKEY

const useStyles = makeStyles(theme =>
    createStyles({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: theme.palette.common.white,
            'backdrop-filter': 'blur(3px)'
        },

        logoEligibilty: {
            textAlign: 'right',
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        }
    })
)

const ErrorComp = () => {
    return <div>todo error</div>
}

const App: React.FC = () => {
    const classes = useStyles()
    const baseClasses = useStylesBase()
    const { search } = useLocation()
    // const history = useHistory()
    const queryParams = ({
        ...{ retailerId: process.env.REACT_APP_DEFAULT_RETAILER_ID, apikey: process.env.REACT_APP_DEFAULT_APIKEY },
        ...queryString.parse(search)
    } as unknown) as RequestParams

    const { theme, updateTheme } = useDynamicTheme()
    const { config } = useConfig(queryParams, updateTheme)

    const Routes = () => (
        <Switch>
            <Route exact path="/" component={Calculator} />
            <Route exact path="/layout" component={LayoutTest} />
            <Route exact path="/layout2" component={LayoutTestTwo} />
            <Route exact path="/layout3" component={LayoutTestThree} />
            <Route exact path="/eligibility" component={EligibilityChecker} />
            <Route exact path="/eligibility/eligible" component={Eligible} />
            <Route exact path="/eligibility/eligible-to-apply" component={EligibleToApply} />
            <Route exact path="/eligibility/thank-you" component={NotEligible} />
            <Route exact path="/error" component={ErrorComp} />
        </Switch>
    )
    return (
        <GoogleReCaptchaProvider reCaptchaKey={sitekey}>
            <div className="App">
                <Container fixed maxWidth="md">
                    <Grid item xs={12} className={classes.logoEligibilty}>
                        <img
                            src="https://www.chrysalisfinance.com/v5/content/images/chrysalis-finance-logo.svg"
                            width="250px"
                            alt="Chrysalis Finance"
                        />
                    </Grid>
                </Container>
                <LoanStateProvider>
                    <FormStateProvider>
                        <Routes />
                    </FormStateProvider>
                </LoanStateProvider>
                <section className={combineClassNames(baseClasses.spacingVerticalTopXs, baseClasses.footerEligibilty)}>
                    <Container fixed maxWidth="md">
                        <Typography variant="caption">
                            Copyright © 2021 Chrysalis Finance Limited. All rights reserved.
                        </Typography>
                        <Typography variant="caption">
                            Chrysalis Finance Limited, PO Box 404, Alderley Edge, Cheshire SK9 0EA.
                        </Typography>
                        <Typography variant="caption">
                            *Finance is Subject to status and full{' '}
                            <Link
                                color="secondary"
                                href="https://chrysalisfinance.co.uk/terms-conditions/"
                                target="_blank"
                            >
                                terms and conditions{' '}
                            </Link>{' '}
                            apply.
                        </Typography>
                        <Typography variant="caption">
                            Registered in England, Company number 6541664 Authorised and Regulated by the Financial
                            Conduct Authority (FRN:63193).
                        </Typography>
                    </Container>
                </section>
            </div>

            <Backdrop className={classes.backdrop} open={!config.loaded || !theme.isUpdated}>
                <CircularProgress />
            </Backdrop>
        </GoogleReCaptchaProvider>
    )
}

export default App
