import {
    lengthValidator,
    requiredValidator,
    emailValidator,
    phoneNumberValidator,
    dobValidator
} from './field.validation'
import { EligibilityFormFields, FieldValidationResult, FieldValidators, FieldErrors } from './form.types'
import { fieldLabels } from './form.labels'
import moment from 'moment'

const labels = fieldLabels as Required<typeof fieldLabels>

const fieldValidators: FieldValidators = {
    Title: [requiredValidator(labels.Title)],
    Forename: [requiredValidator(labels.Forename), lengthValidator(labels.Forename, 1, 15)],
    Surname: [requiredValidator(labels.Surname), lengthValidator(labels.Surname, 1, 30)],
    Gender: [requiredValidator(labels.Gender)],
    DOB: [requiredValidator(labels.DOB), dobValidator(labels.DOB)],
    EmailAddress: [
        requiredValidator(labels.EmailAddress),
        emailValidator(labels.EmailAddress),
        lengthValidator(labels.EmailAddress, 1, 150)
    ],
    ContactNo: [
        requiredValidator(labels.ContactNo),
        phoneNumberValidator(labels.ContactNo),
        lengthValidator(labels.ContactNo, 1, 50)
    ],
    PostCode: [requiredValidator(labels.PostCode), lengthValidator(labels.PostCode, 3, 8)],
    HouseNameFlat: [lengthValidator(labels.HouseNameFlat, 0, 40)],
    HouseNumber: [lengthValidator(labels.HouseNumber, 0, 40)],
    Street: [requiredValidator(labels.Street), lengthValidator(labels.Street, 1, 40)],
    // Address2: [requiredValidator(labels.Address2), lengthValidator(labels.Address2, 0, 40)],
    // Address3: [requiredValidator(labels.Address3), lengthValidator(labels.Address3, 0, 40)],
    Town: [requiredValidator(labels.Town), lengthValidator(labels.Town, 1, 40)],
    County: [requiredValidator(labels.County), lengthValidator(labels.County, 1, 40)]
}

const validateField = (fieldName: keyof EligibilityFormFields, value = '') => {
    const fv = fieldValidators[fieldName]
    let fieldErrors: FieldValidationResult[] = []
    if (fv && fv.length) {
        const results = fv.map(v => v(value))
        fieldErrors = results.filter(r => !r.isValid)
    }

    return {
        [fieldName]:
            fieldErrors && fieldErrors.length
                ? fieldErrors[0]
                : {
                      isValid: true,
                      errors: fieldErrors
                  }
    }
}

const validateAllFields = (form: EligibilityFormFields): FieldErrors => {
    const fields = Object.keys(fieldLabels) as (keyof EligibilityFormFields)[]
    const results: FieldErrors = fields
        .map(k => validateField(k, form[k]))
        .filter(f => !f[Object.keys(f)[0]].isValid)
        .reduce((p, c) => {
            return { ...p, ...c }
        }, {})

    if (!form.HouseNameFlat && !form.HouseNumber) {
        results.HouseNameFlat = {
            isValid: false,
            errors: [{ message: `Enter a ${labels.HouseNameFlat} or ${labels.HouseNumber}` }]
        }
    }
    return results
}

const maxDob = moment()
    .subtract(18, 'years')
    .toDate()

const minDob = moment()
    .subtract(100, 'years')
    .toDate()

export { fieldValidators, validateField, validateAllFields, minDob, maxDob }
