import BigNumber from 'bignumber.js'

export const formattedNumber = (value: number | undefined, decimals = 2, defaultValue = 0) => {
    const num = value !== undefined ? value : defaultValue
    return new BigNumber(num).toFixed(decimals)
}

export const formattedAmount = (value: number | string | undefined, decimals = 2, defaultValue = 0) => {
    const num = value !== undefined ? value : defaultValue
    return new BigNumber(num).toFormat(decimals)
}
