import { Overrides } from '@material-ui/core/styles/overrides'

const inputStyles: Overrides = {
    // MuiOutlinedInput: {
    //     root: {
    //         borderRadius: 2
    //     },
    //     input: {
    //         padding: 7
    //     },
    //     adornedStart: {
    //         paddingLeft: 3
    //     }
    // },
    // MuiInputAdornment: {
    //     positionStart: {
    //         marginRight: 1
    //     }
    // }
}

const overrides = {
    ...inputStyles
} as Overrides

export { overrides }
