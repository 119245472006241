import React, { useState } from 'react'
import { ButtonBaseProps, Button, LinearProgress } from '@material-ui/core'
import { StateProps } from '../../state/form.state'

interface SubmitButtonProps {
    onSubmitForm: (setLoading: (loading: boolean) => void) => void
}

type Props = ButtonBaseProps & SubmitButtonProps & Pick<StateProps, 'state'>

const SubmitButton = (props: Props) => {
    const { onSubmitForm } = props
    const [loading, setLoading] = useState(false)
    return (
        <>
            <Button
                variant="contained"
                color="secondary"
                disabled={loading}
                onClick={event => {
                    event.preventDefault()
                    setLoading(true)
                    onSubmitForm(setLoading)
                }}
            >
                Submit eligibility details
            </Button>
            {loading && <LinearProgress />}
        </>
    )
}

export { SubmitButton }
