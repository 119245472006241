import clsx from 'clsx'

import { Theme, makeStyles } from '@material-ui/core'

const useStylesBase = makeStyles((theme: Theme) => ({
    spacingVertical: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        [theme.breakpoints.only('sm')]: {
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3)
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2)
        }
    },
    spacingVerticalTopLrg: {
        paddingTop: theme.spacing(4),
        [theme.breakpoints.only('sm')]: {
            paddingTop: theme.spacing(3)
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: theme.spacing(2)
        }
    },
    spacingVerticalTopMd: {
        paddingTop: theme.spacing(3),
        [theme.breakpoints.only('sm')]: {
            paddingTop: theme.spacing(2)
        },
        [theme.breakpoints.only('xs')]: {
            paddingTop: theme.spacing(1)
        }
    },
    spacingVerticalTopSm: {
        paddingTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            paddingTop: theme.spacing(1)
        }
    },
    spacingVerticalTopXs: {
        paddingTop: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            paddingTop: 4
        }
    },

    footerEligibilty: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        textAlign: 'center',
        padding: theme.spacing(0.5),
        '& span': {
            display: 'block',
            lineHeight: 1.3
        },
        '& a': {
            color: theme.palette.common.white
        }
    },
    largeTick: {
        fontSize: '3rem',
        marginBottom: theme.spacing(1)
    }
}))

const combineClassNames = clsx

export { useStylesBase, combineClassNames }
